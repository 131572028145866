import { DocumentAnalysisModelEnum } from '../../types';
import { documentAnalysisIBANDefinition } from './iban';
import { documentAnalysisRegistrationCertificateDefinition } from './registration-certificate';
import { documentAnalysisProofOfAddressDefinition } from './proof-of-address';

export const documentAnalysisDefinitionsRecord = {
  [DocumentAnalysisModelEnum.iban]: documentAnalysisIBANDefinition,
  [DocumentAnalysisModelEnum.registration_certificate]:
    documentAnalysisRegistrationCertificateDefinition,
  [DocumentAnalysisModelEnum.proof_of_address]:
    documentAnalysisProofOfAddressDefinition,
} as const;

export function getDocumentAnalysisDefinition<
  Model extends Exclude<
    DocumentAnalysisModelEnum,
    DocumentAnalysisModelEnum.prompt
  >,
>(model: Model): (typeof documentAnalysisDefinitionsRecord)[Model] {
  return documentAnalysisDefinitionsRecord[model];
}
