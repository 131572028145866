import {
  Alert,
  AlertIcon,
  Button,
  Icon,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { SendIcon } from 'lucide-react';
import {
  Check,
  CheckResultEnum,
  CheckStatusEnum,
  CheckTypeEnum,
  Company,
  DocumentAnalysisEntity,
  DocumentAnalysisModelEnum,
  Individual,
  REJECTED_ANALYSIS_DOCUMENT_PROCESSING_DELAY_IN_MINUTES,
} from 'shared-domain';
import { DocumentCheckAnalysisDetailedResults } from '../../checks/document-analysis';
import { useApi } from 'frontend-common';
import { datadogRum } from '@datadog/browser-rum';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function DocumentAnalysisReport({
  check,
  entity,
  onClose,
  refetch,
  submitLoading = false,
}: {
  check: Check;
  entity: Company | Individual;
  onClose: () => void;
  refetch: (check: Check) => void;
  submitLoading: boolean;
}) {
  const [forceReviewLoading, setForceReviewLoading] = useState(false);
  const checkData = check.data;
  const toast = useToast();
  const api = useApi();
  const { t } = useTranslation();

  const forceReview = async () => {
    setForceReviewLoading(true);

    const { data } = await api
      .post('/dotfile/force_review', { checkId: check.id })
      .then((response) => {
        onClose();
        return response;
      })
      .catch((err) => {
        const error = {
          type: err.response.status?.toString(),
          message: err.response.data.message,
        };
        datadogRum.addError(error);

        toast({
          title: 'An error occurred',
          description: 'Unable to force review.',
          variant: 'toast_error',
          status: 'error',
          isClosable: false,
          position: 'bottom-right',
        });
        return { data: null };
      });

    await refetch(data);
    setForceReviewLoading(false);
  };

  if (
    check.type !== CheckTypeEnum.document ||
    ![CheckStatusEnum.in_progress, CheckStatusEnum.rejected].includes(
      check.status,
    ) ||
    !checkData?.detailed_results?.document_analysis
  ) {
    return null;
  }

  const canForceReview = [
    CheckStatusEnum.in_progress,
    CheckStatusEnum.need_review,
  ].includes(check.status);

  const includeErrorOrRejected = Object.values(
    checkData.detailed_results.document_analysis,
  ).some(({ result }) =>
    [CheckResultEnum.error, CheckResultEnum.rejected].includes(result),
  );

  const model = check.data.settings.document_analysis?.parameters
    .model as DocumentAnalysisModelEnum;

  const isPromptModel = model === DocumentAnalysisModelEnum.prompt;

  if (!includeErrorOrRejected && !isPromptModel) return null;

  const documentAnalysisEntity: DocumentAnalysisEntity =
    'registration_number' in entity
      ? {
          company: entity,
        }
      : { individual: entity };

  return (
    <VStack mb="4" spacing="4" align="flex-start">
      <VStack align="flex-start" spacing="2" w="full">
        <Alert status="error">
          <AlertIcon />
          <Text>
            {isPromptModel
              ? t('steps.checks_list.document_analysis.generic_failed')
              : t('steps.checks_list.document_analysis.invalid_alert')}
          </Text>
        </Alert>
        <DocumentCheckAnalysisDetailedResults
          check={check}
          entity={documentAnalysisEntity}
        />
      </VStack>
      <VStack align="flex-start" spacing="2">
        <Tooltip
          isDisabled={canForceReview}
          label={t(
            'steps.checks_list.document_analysis.force_review_disabled_tooltip',
          )}
        >
          <Button
            isDisabled={!canForceReview}
            isLoading={forceReviewLoading || submitLoading}
            onClick={() => forceReview()}
            leftIcon={<Icon as={SendIcon} />}
            variant="outline"
          >
            {t('steps.checks_list.document_analysis.force_review')}
          </Button>
        </Tooltip>
        {check.status === CheckStatusEnum.in_progress && (
          <Text color="gray.500">
            {t('steps.checks_list.document_analysis.auto_submit', {
              count: REJECTED_ANALYSIS_DOCUMENT_PROCESSING_DELAY_IN_MINUTES,
            })}
          </Text>
        )}
      </VStack>
    </VStack>
  );
}
